.squad-content h5 {
  margin-bottom: 5px;
}

.small-text {
  /* font-size: 0.95em; */
  color: var(--color-gray);
}

.squad-content b {
  font-family: 'galano-reg', sans-serif;
}

img.support-squad-headshot {
  width: 40%;
  max-width: 300px;
}

@media only screen and (max-width: 450px) {
  img.support-squad-headshot {
    width: 80%;
  }
}