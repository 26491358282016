.warning-box {
  display: flex;
  color: red;
  border: 1px solid red;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.warning-box i {
  margin-right: 10px;
}

.warning-box.hide {
  display: none;
}