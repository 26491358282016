.landing-page {
  padding: 80px 5%;
}

.hero-img {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.hero-img h1 {
  font-size: 2.5em;
  padding-left: 5%;
  text-align: center;
  width: 400px;
  color: var(--color-pink1);
}

.landing-page h3 {
  text-align: center;
  color: var(--color-pink1);
  font-size: 2.2em;
  margin-bottom: 20px;
}

.landing-page-section {
  margin-bottom: 100px;
}

.landing-page-card {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 60px 0;
}

.landing-page-card img {
  width: 50%;
  margin-right: 5%;
}

.landing-page-card.reverse {
  flex-direction: row-reverse;
}

.landing-page-card.reverse img {
  margin-left: 5%;
  margin-right: 0;
}

.landing-page-card h5 {
  display: inline-block;
}

.landing-page-card-content {
  margin-left: 50px;
}

@media only screen and (max-width: 750px) {
  .hero-img h1 {
    font-size: 2em;
    width: 200px;
  }
}

@media only screen and (max-width: 450px) {
  .hero-img h1 {
    padding-left: 10%;
    font-size: 1.5em;
  }

  .landing-page-card,
  .landing-page-card.reverse {
    flex-direction: column;
  }

  .landing-page-card img,
  .landing-page-card.reverse img {
    width: 80%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .landing-page-card-content {
    margin-left: 0;
  }
}