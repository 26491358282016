#header {
  max-width: 100%;
  height: 70px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
}

#logo a {
  color: var(--color-pink1);
  font-size: 1.5em;
}

#logo a:hover {
  color: var(--color-accent);
}

#nav {
  display: flex;
  align-items: center;
}

#nav a,
#nav form {
  margin-left: 10px;
  margin-right: 10px;
}

#mobile-nav button {
  width: 70px;
  height: 70px;
}

#nav-side-list {
  padding: 10%;
  text-align: center;
}

#nav-side-list-logo {
  margin-bottom: 20px;
}

#nav-side-list-logo a {
  color: var(--color-pink1);
  font-size: 1.5em;
}

#nav-side-list-logo a:hover {
  color: var(--color-accent);
}

#social-media {
  display: inline-block;
  position: fixed;
  left: 10px;
  bottom: 20px;
  z-index: 100;
}

#social-media a {
  display: block;
  margin-bottom: 5px;
}

#social-media i {
  color: var(--color-accent);
}

.MuiDrawer-paper {
  width: 100% !important;
  background-color: var(--color-background) !important;
}

#banner {
  position: fixed;
  top: 70px;
  z-index: 99;
  width: 100%;
  height: 50px;
  background-color: var(--color-pink);
  text-align: center;
  padding-top: 15px;
  font-size: 1.1em;
  color: var(--color-darkGray);
  cursor: pointer;
  transition-duration: 0.3s;
}

#banner:hover {
  background-color: var(--color-accent);
  color: white;
}
