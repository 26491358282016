:root {
  --color-pink: #F5C3CF;
  --color-pink1: #F087A0;
  --color-pink2: #F08EB7;
  --color-pink3: #F4ADA5;
  --color-blue: #819aff;
  --color-paleBlue: #adbdff;
  --color-darkGray: #444;
  --color-gray: #9c969a;
  --color-lightGray: #b5b5b5;
  --color-accent: #f50057;
  --color-background: #FDF3EA;
}

@font-face {
  font-family: 'galano-semibold';
  src: url('./galano/galanogrotesquealt-semibold-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-light';
  src: url('./galano/galanogrotesquealt-light-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-reg';
  src: url('./galano/GalanoGrotesque-Regular-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-bold';
  src: url('./galano/GalanoGrotesque-Bold-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

a, p, h1, h2, h4, h5 {
  color: var(--color-darkGray);
}

h3 {
  font-family: 'galano-semibold', sans-serif;
  font-size: 1.8em;
  letter-spacing: 0.1em;
}

h2 {
  font-family: 'galano-bold', sans-serif;
}

h4 {
  font-family: 'galano-semibold', sans-serif;
}

h5 {
  font-family: 'galano-reg', sans-serif;
  font-size: 1.5em;
}

.divider {
  border-bottom: 1px solid rgb(235, 235, 235);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

p, li {
  font-family: 'galano-light', sans-serif;
  font-size: 15px;
  letter-spacing: .05em;
  text-decoration: none;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  font-family: 'galano-reg', sans-serif;
  color: var(--color-darkGray);
  font-size: 14px;
  letter-spacing: .12em;
}

a:hover {
  color: var(--color-darkGray);
}

a.underline:hover {
  background-image: linear-gradient(180deg, var(--color-pink), var(--color-pink));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

.underline {
  background-image: linear-gradient(180deg, var(--color-pink), var(--color-pink));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

b {
  font-family: 'galano-semibold', sans-serif;
}

.clearFloat {
  clear: both;
}

/* border box animation  */

.box {
  border: none;
  position: relative;
  background-color: transparent;
  font-size: 0.8em;
  padding: 10px;
  cursor: pointer;
}

.box::before,
::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  border: 2px solid transparent;
  width: 0;
  height: 0;
}

.box::before {
  top: 0;
  left: 0;
}

.box::after {
  bottom: 0;
  right: 0;
}

.box:hover {
  color: var(--color-accent);
  border: none;
  transition: all 1s;
}

.box:hover::before {
  width: 100%;
  height: 100%;
  border-top-color: var(--color-accent);
  border-right-color: var(--color-accent);
  transition:
    width 0.25s ease-out,
    height 0.25s ease-out 0.25s;
}

.box:hover::after {
  width: 100%;
  height: 100%;
  border-bottom-color: var(--color-accent);
  border-left-color: var(--color-accent);
  transition:
    border-color 0s ease-out 0.5s,
    width 0.25s ease-out 0.5s,
    height 0.25s ease-out 0.75s;
}

/* button border */

a.button-outline,
button.button-outline {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: relative;
  z-index: 0;
  padding: .75rem 1.5625rem;
  -webkit-transform: translate3d(.375rem,-.375rem,0);
  transform: translate3d(.375rem,-.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: black;
  height: auto;
  margin-bottom: .375rem;
  text-align: center;
  text-decoration: none;
  outline: none;
  min-width: 9rem;
  max-width: 80vw;
  width: 75%;
  font-family: 'galano-bold', sans-serif;
}

a.button-outline:hover,
a.button-outline:hover::before,
button.button-outline:hover,
button.button-outline:hover::before {
  color: black;
  transform: translateZ(0);
}

a.button-outline:before,
button.button-outline:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--color-pink);
  content: "";
  -webkit-transform: translate3d(-.375rem,.375rem,0);
  transform: translate3d(-.375rem,.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  border-radius: 5px;
}

a.button-outline:after,
button.button-outline:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid;
  content: "";
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  border-radius: 5px;
}

.button-border h3 {
  font-size: 1em;
  letter-spacing: 0.15em;
}

.page {
  padding-top: 80px;
}

.pac-logo,
.pac-container {  
  z-index: 9999999 !important;  
  background-color: #fff;
  position: absolute!important;
  /* z-index: 1000; */
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
}