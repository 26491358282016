.process-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  align-items: center;
  margin: 20px;
}

.process-page-hero {
  display: flex;
  flex-wrap: wrap;
}

.process-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.process-card h4 {
  margin-top: 20px;
  text-align: center;
  font-size: 1.2em;
}

.process-card:hover h4 {
  background-image: linear-gradient(180deg, var(--color-pink), var(--color-pink));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

.process-page {
  margin: 70px auto;
  max-width: 700px;
  width: 90%;
}

.process-page-hero {
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-page-hero h1 {
  color: var(--color-pink1);
  margin-left: 20px;
}

.takeaway-box {
  width: 70%;
  padding: 30px;
  border: 4px solid var(--color-accent);
  border-radius: 20px;
  margin: 40px auto;
  text-align: center;
  color: var(--color-accent);
  font-family: 'galano-bold', sans-serif;
}

.takeaway-box p,
.takeaway-box li {
  text-align: left;
}

.takeaway-box h4 {
  margin-top: 0 !important;
  color: var(--color-accent) !important;
}

.takeaway-box h5 {
  font-size: 1em;
  text-align: left;
  color: var(--color-accent);
}

.process-page b,
.process-page a,
.process-page li,
.process-page p {
  font-family: 'open sans', sans-serif;
  color: #444;
}

.process-page p {
  margin-top: 30px;
}

.process-page h4 {
  font-size: 1.4em;
  color: var(--color-pink1);
  margin-top: 50px;
}

.process-page img {
  display: block;
  margin: 15px auto;
}

@media only screen and (max-width: 450px) {
  .takeaway-box {
    width: 90%;
    padding: 20px;
  }
}

.MuiTableCell-head {
  font-size: 1em !important;
  font-weight: 700 !important;
  color: var(--color-pink1) !important;
}

.MuiTableCell-root {
  font-size: 0.95em !important;
}